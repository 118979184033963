<script setup lang="ts">
import {getNavbar} from "~/utils/base/common-utils";

const navbar = ref(getNavbar())

const makeItems = (options: any) =>
    options.map((item: any) => {
      return [
        {
          label: item.fa_name,
          icon: item.icon,
          to: item.active ? item.slug : ''
        }
      ]
    })

</script>

<template>
  <nav class="flex justify-between items-center">
    <ul class="flex items-center justify-between">
      <li
          v-for="(nav, i) in navbar"
          :key="i"
      >
        <u-dropdown-menu
            v-if="nav.options?.length"
            :items="makeItems(nav.options)"
            :popper="{ placement: 'bottom-start' }"
            :ui="{base: 'p-2', width: 'w-60', padding: 'p-1', item: {active: 'text-primary bg-primary-50'}}"
        >
          <u-button
              color="white"
              variant="soft"
              :label="nav.fa_name"
              :trailing-icon="chevronDownIcon()"
              :leading-icon="nav.icon"
              :ui="{base: 'text-gray-800 hover:text-primary transition-colors duration-400'}"
          />
        </u-dropdown-menu>
        <u-button
            v-else
            :disabled="!nav.active"
            :to="nav?.slug"
            color="white"
            variant="soft"
            :leading-icon="nav.icon"
            :ui="{base: 'text-gray-800 hover:text-primary transition-colors duration-400'}"
            active-class="text-primary"
            :label="nav.fa_name"
        />
      </li>
    </ul>
  </nav>
</template>
